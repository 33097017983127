import { createStore } from 'vuex';
import LS from '@/utils/localStorage';

export default createStore({
	state: {
		// apiUrl: 'https://back.cryptoexchange.global',
		// apiUrl: 'https://brand.cryptoexchange.global',
		apiUrl: 'https://site.tkcpay.io',
		publicKey:
			'-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAppoCmwMWk/cIn0zXJtGY\n4y2lsvS9tME6xHEj7+2AT9kDLwGwtzpcJaog/F2SDG1M1bzQ3pKd/Eie245Wznf7\nGpf8c8cN8dl+TiVV/zhDfJ1/Wz24CtxM2j7EskthWISmqBB0Swoz58+LTjrh3urD\nwi9Eu/gB5fQx5cGxnyn2XEYMYjcUlEXKYMXNbM7g3G/g3JiEMKyizBAdDW1mBMIn\n2CGh9nTB+/rTgdmpq6IG3plEJLbGuQz5hFE2Lf8qVaOr8OicbwgpN/lEjlUqCwI8\niNcte23R7+yTDHRBRYkzMr9UxHMNkX0+ufz2FuUsMqOWTi8yxneaPRznWS8zumwM\nXwIDAQAB\n-----END PUBLIC KEY-----',
		secretKey: '1hO7d8LJxYBXZytUWbpOwqVv6CAsEKAgeTxIY1I4kgKPKyUFvJuGYBlSD0pdBGDi',
		isLoading: false,
		errorCount: 0,
		blockMode: false,
		mainPage: '',
		subPage: '',
		detailType: LS.getItem('detailType') || '',
		detailIdx: LS.getItem('detailIdx') || 0,
		locale: LS.getItem('locale') || 'en',
		accessToken: LS.getItem('accessToken') || '',
		expireAccessToken: LS.getItem('expireAccessToken') || 0,
		refreshToken: LS.getItem('refreshToken') || '',
		expireRefreshToken: LS.getItem('expireRefreshToken') || 0,
		userId: LS.getItem('userId') || '',
		userEmail: LS.getItem('userEmail') || '',
		userName: LS.getItem('userName') || '',
		userCode: LS.getItem('userCode') || '',
		referral: LS.getItem('referral') || ''
	},
	getters: {
		getAccessToken: state => {
			return state.accessToken;
		},
		getRefreshToken: state => {
			return state.refreshToken;
		},
		getExpireAccessToken: state => {
			return state.expireAccessToken;
		},
		getExpireRefreshToken: state => {
			return state.expireRefreshToken;
		}
	},
	mutations: {
		setLocale(state, { lang }) {
			state.locale = lang;
			LS.setItem('locale', lang);
		},
		setReferral(state, { referral }) {
			state.referral = referral;
			LS.setItem('referral', referral);
		},
		setClearToken(state) {
			state.expireAccessToken = 0;
			state.expireRefreshToken = 0;
			state.userId = '';
			state.userEmail = '';
			state.userName = '';
			state.userCode = '';

			LS.clear();
		},
		setInitToken(state) {
			state.expireAccessToken = 0;
			state.expireRefreshToken = 0;

			LS.setItem('expireAccessToken', '0');
			LS.setItem('expireRefreshToken', '0');
		},
		setAccessToken(state, { token, expireAt }) {
			const currentDate = new Date().getTime() / 1000;

			state.accessToken = token;
			state.expireAccessToken = currentDate + expireAt;

			LS.setItem('accessToken', token);
			LS.setItem('expireAccessToken', currentDate + expireAt);
		},
		setRefreshToken(state, { token, expireAt }) {
			const currentDate = new Date().getTime() / 1000;

			state.refreshToken = token;
			state.expireRefreshToken = currentDate + expireAt;

			LS.setItem('refreshToken', token);
			LS.setItem('expireRefreshToken', currentDate + expireAt);
		},
		setUserId(state, { userId }) {
			state.userId = userId;

			LS.setItem('userId', userId);
		},
		setUserEmail(state, { userEmail }) {
			state.userEmail = userEmail;

			LS.setItem('userEmail', userEmail);
		},
		setUserName(state, { userName }) {
			state.userName = userName;

			LS.setItem('userName', userName);
		},
		setUserCode(state, { userCode }) {
			state.userCode = userCode;

			LS.setItem('userCode', userCode);
		},
		setDetailType(state, { type }) {
			state.detailType = type;

			LS.setItem('detailType', type);
		},
		setDetailIdx(state, { idx }) {
			state.detailIdx = idx;

			LS.setItem('detailIdx', idx);
		}
	},
	actions: {},
	modules: {}
});
