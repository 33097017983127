export const localeTypes = {
	EN: 'en',
	KR: 'kr',
	TH: 'th'
};

export const locales = [
	{
		name: localeTypes.EN,
		label: 'English',
		type: 'english'
	},
	{
		name: localeTypes.KR,
		label: '한국어',
		type: 'korean'
	},
	{
		name: localeTypes.TH,
		label: 'ภาษาไทย',
		type: 'thai'
	}
];
